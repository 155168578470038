<template>
  <div v-if="showModuloForm === false">
    <MarketingModules></MarketingModules>
  </div>

  <div class="row" v-else-if="showModuloForm === true">
    <CarrosselTemplate
      v-for="slider in sliders"
      :key="slider.idcarrossel"
      :slider="slider"
      :setores-folha="setoresFolhaSegmento"
      :segmentos-cliente="segmentosCliente"
      :image-editor-mode="false"
      :product-editor-mode="true"
      @refresh="refresh"
      @delete="onDeleteCarrossel(slider.idcarrossel)"
    />

    <div class="create-new-carrossel">
      <input
        type="text"
        class="form-control input-md"
        style="border: 0px; width: 50%"
        placeholder="Para criar uma nova vitrine, insira um nome aqui"
        v-model="newSliderTitle"
        @keyup.enter="createNewSlider"
      />
    </div>
  </div>
</template>

<script>
import CarrosselTemplate from '../carrossel/template/CarrosselTemplate.vue'
import { SEGMENTO_LIST, SEGMENTO_CLIENTE_LIST } from '@/store/actions/segmento'
import {
  CARROSSEL_LIST,
  CARROSSEL_CREATE,
  CARROSSEL_DELETE
} from '@/store/actions/carrossel'
import { TODOS_SETORES } from '@/store/actions/setor'
import { ModulesEnum } from '@/store/actions/modulesEnum'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      newSliderTitle: '',
      sliders: [],
      setoresFolhaSegmento: [],
      modulosForm: [
        ModulesEnum.PORTFOLIO_WEB,
        ModulesEnum.ORCAMENTO_WEB,
        ModulesEnum.ORCAMENTO_APP,
        ModulesEnum.VENDAMAIS_WEB,
        ModulesEnum.VENDAMAIS_APP,
        ModulesEnum.WEB_COMMERCE,
        ModulesEnum.COMMERCE_APP
      ]
    }
  },
  components: {
    CarrosselTemplate
  },
  computed: {
    ...mapGetters(['moduloFornecedorAtivo', 'getFornecedorId']),
    showModuloForm() {
      return this.moduloFornecedorAtivo(this.modulosForm)
    }
  },
  created() {
    this.getSegmentos()
    this.getSegmentoCliente()
  },
  methods: {
    getSegmentos() {
      this.$store.dispatch(SEGMENTO_LIST).then(segmentos => {
        const idSegmentos = segmentos.map(s => s.idsegmento)

        segmentos.forEach(segmento => {
          this.$store
            .dispatch(TODOS_SETORES, segmento.idsegmento)
            .then(async folhas => {
              const setoresFolhaSegmento = folhas.filter(
                folha => idSegmentos.indexOf(folha.idsegmento) >= 0
              )
              this.setoresFolhaSegmento = setoresFolhaSegmento || []
              const sliders = await this.$store.dispatch(
                CARROSSEL_LIST,
                'PRODUCT_CATEGORY'
              )
              this.sliders = sliders || []
            })
        })
      })
    },
    getSegmentoCliente() {
      this.$store
        .dispatch(SEGMENTO_CLIENTE_LIST, {
          idfornecedor: this.getFornecedorId
        })
        .then(res => {
          this.segmentosCliente = [{ text: 'Nenhum segmento', value: null }]

          const segmentoReformulado = res.map(segmento => {
            return { text: segmento.nome, value: segmento.idsegmentocliente }
          })

          this.segmentosCliente = this.segmentosCliente.concat(
            segmentoReformulado
          )
        })
    },
    createNewSlider() {
      this.$store
        .dispatch(CARROSSEL_CREATE, {
          title: this.newSliderTitle,
          tipoConteudo: 'PRODUCT_CATEGORY'
        })
        .then(newCarrossel => {
          this.sliders.push(newCarrossel)
          this.newSliderTitle = ''
        })
    },
    refresh(novoCarrossel) {
      this.sliders.map(function(slider) {
        if (slider.idcarrossel == novoCarrossel.idcarrossel) {
          slider.slides = novoCarrossel.slides
        }
      })
    },
    async onDeleteCarrossel(idcarrossel) {
      this.$swal
        .fire({
          title: `Apagar essa vitrine?`,
          text: 'Esta operação é irreversível. Continuar?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          reverseButtons: true
        })
        .then(result => {
          if (result.value) {
            this.$store
              .dispatch(CARROSSEL_DELETE, idcarrossel)
              .then(() => {
                this.sliders = Array.from(this.sliders).filter(function(
                  slider
                ) {
                  return slider.idcarrossel !== idcarrossel
                })

                this.$vueOnToast.pop(
                  'success',
                  'Carrossel excluído com sucesso.'
                )
              })
              .catch(() => {
                this.$vueOnToast.pop('error', 'Erro ao excluir o carrossel.')
              })
          }
        })
    }
  }
}
</script>

<style scoped>
input {
  font-size: 18px;
  color: #6a6c6f;
  font-weight: bold;
  width: 100%;
  height: 30px;
}

input:focus {
  outline: 0;
}

.create-new-carrossel {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 5px;
}

.create-new-carrossel input {
  margin: 6px 25px;
}
</style>
